import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import RichTextBlock from './rich-text-block';
import { parseHTML } from '../../helpers/parseHTML';
import SmartLink from '../global/smart-link';
import gtmEvent from '../../helpers/gtmEvent';
import { set } from 'lodash';

// Props
interface props {
  componentData: {
    title: string
    auxTitle?: string
    tabItems: any[]

  }
  hideHeader?: boolean

}

export default function TabModule({ componentData, hideHeader }: props) {

  const [activeTab, setActiveTab] = useState(null);
  const {
    title,
    auxTitle,
    tabItems,
  } = componentData;

  if (activeTab === null && tabItems && tabItems.length > 0) {
    let defaultSelected = tabItems && tabItems.find((cat) => cat.defaultSelected);
    if (!defaultSelected) {
      defaultSelected = tabItems[0]
    }
    setActiveTab(defaultSelected.id)
  }


  return (
    <div className="tab">
      <div className='outer-container'>
        <div className="inner-container">
          <div className={`tab-header`}>
            {!hideHeader && <div className={`tab-header__top`}>
              <h1 className={`tab-header__title`}>{title}</h1>
            </div>}
            <div className={`tab-header__bottom`}>
              {auxTitle &&
                <p className="tab-header__tab-aux">{auxTitle}</p>
              }

              {tabItems && tabItems.length > 0 && tabItems.map((cat, index) => {
                return (
                  <button
                    className={`tab-header__tab ${activeTab === cat.id ? 'active' : ''}`}
                    key={`tab-${index}`}
                    onClick={() => setActiveTab(cat.id)}
                  >
                    {cat.title}
                  </button>
                )
              })}
            </div>
          </div>
          <div className='tab-content'>
            {tabItems && tabItems.length > 0 && tabItems.map((tabItem, index) => {
              return (
                tabItem.id === activeTab &&
                <div className={`tab-content__item`} key={`tab-content-${index}`}>
                  {tabItem.content && tabItem.content.length > 0 && tabItem.content.map((content, index) => {

                    switch (content.__typename) {
                      case "DatoCmsTitleText":
                        return (
                          <div className="tab-content__title-text">
                            <h2>{content.title}</h2>
                            <p>{content.text}</p>
                          </div>
                        )
                      case "DatoCmsRichText":
                        return (
                          <div key={index}>
                            {parseHTML(content.text)}
                          </div>
                        )
                      case 'DatoCmsCtaButton':
                        return (
                          <SmartLink
                            data={content.linkUrl}
                            key={`key-${index}`}
                            className={`accordion-module__accordion-cta not-rte`}
                            onClick={() => gtmEvent({
                              event: 'tabCTA',
                              tabTopic: title,
                              tabTitle: content.title,
                              ctaTitle: content.linkText,
                            })}
                          >
                            {content.linkText}
                          </SmartLink>
                        )
                      case 'DatoCmsEmbedModule':
                        return (
                          <div key={index} className="article-module-embed">
                            <div className="outer-container">
                              <div className="inner-container">
                                <div className="article-module-embed__content rte">
                                  {parseHTML(content.content)}
                                </div>
                                <SmartLink
                                  className="article-module-embed__transcript"
                                  data={content.transcriptLink}
                                >
                                  {content.transcriptText}
                                </SmartLink>
                              </div>
                            </div>
                          </div>
                        )
                    }
                    return <>{content.__typename} {index}</>

                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>


    </div>

  )
}
